/*contrast issues*/
/* \core\public\shared\assets\css\screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container ul.fly-out-menu-list li a:hover {
	background: #1f78d1 !important;
	color: #ffffff !important;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #1f78d1 !important;
	color: #ffffff !important;
}

/* \core\libraries\jquery.mmenu\assets\5.6.5\css\jquery.mmenu.all.css */
.mm-menu .mm-search input {
	background: #333333;
	color: #9c9c9c;
}

.mm-menu.mm-theme-dark .mm-search input {
	background: #666;
	color: #fff;
}

.mm-menu.mm-theme-white .mm-search input {
	background: #fff;
	color: #000;
}

.mm-menu.mm-theme-black .mm-search input {
	background: #000;
	color: #fff;
}

/* \core\libraries\tablesaw\assets\2.0.3\tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1876bf;
	background-color: #ffffff;
	cursor: pointer;
}

/* \core\public\shared\assets\js\jquery\fullcalendar\3.2.0\fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #f2f2f2;
	color: #6e6e6e;
	font-family: inherit;
}

/* \core\website\modules\events\css\screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #757575;
	color: #000000;
	font-family: inherit;
}

/*relative font units*/
/* \core\libraries\jquery.mmenu\assets\5.6.5\css\jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
}

em.mm-counter {
	font-size: 0.875rem;
}

.mm-search input {
	font-size: 0.875rem;
}

.mm-noresultsmsg {
	font-size: 1.3125rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* \core\public\shared\assets\js\jquery\fullcalendar\3.2.0\fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}

/* submission forms screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}

/*submission forms print.css*/
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* \core\website\modules\jobs\css\screen.css */
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment div.required_field_information {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment .all_fields_required_note {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment form div.form-field label {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field label em,
.required {
	font-size: 1rem;
}

#jobs_module div.application-for-employment form div.form-field span.text input {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span.textarea textarea {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span label {
	font-size: 0.5625rem;
}

#jobs_module div.application-for-employment form div.form-field span.checkbox label {
	font-size: 0.75rem;
	line-height: 1.125rem;
}

#jobs_module div.application-for-employment form div.form-field select {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span small {
	font-size: 0.5625rem;
}

/* Information conveyed with color  */
/* \core\libraries\jquery.mmenu\assets\5.6.5\css\jquery.mmenu.all.css */
.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: rgba(0, 0, 0, 0.3);
	font-family: inherit;
}

.mm-menu em.mm-counter {
	color: rgba(0, 0, 0, 0.3);
	font-family: inherit;
}

.mm-menu .mm-search input {
	background: rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.7);
	font-family: inherit;
}

.mm-menu .mm-noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-family: inherit;
}

.mm-menu .mm-indexer a {
	color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.mm-menu.mm-theme-dark .mm-navbar a,
.mm-menu.mm-theme-dark .mm-navbar > *,
.mm-menu.mm-theme-dark em.mm-counter {
	color: rgba(255, 255, 255, 0.4);
	font-family: inherit;
}

.mm-menu.mm-theme-dark .mm-search input {
	background: rgba(255, 255, 255, 0.3);
	color: rgba(255, 255, 255, 0.8);
	font-family: inherit;
}

.mm-menu.mm-theme-dark .mm-indexer a,
.mm-menu.mm-theme-dark .mm-noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-family: inherit;
}

.mm-menu.mm-theme-white .mm-navbar a,
.mm-menu.mm-theme-white .mm-navbar > *,
.mm-menu.mm-theme-white em.mm-counter {
	color: rgba(0, 0, 0, 0.3);
	font-family: inherit;
}

.mm-menu.mm-theme-white .mm-search input {
	background: rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.6);
	font-family: inherit;
}

.mm-menu.mm-theme-white .mm-indexer a,
.mm-menu.mm-theme-white .mm-noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-family: inherit;
}

.mm-menu.mm-theme-black .mm-navbar a,
.mm-menu.mm-theme-black .mm-navbar > *,
.mm-menu.mm-theme-black em.mm-counter {
	color: rgba(255, 255, 255, 0.4);
	font-family: inherit;
}

.mm-menu.mm-theme-black .mm-search input {
	background: rgba(255, 255, 255, 0.3);
	color: rgba(255, 255, 255, 0.6);
	font-family: inherit;
}

.mm-menu.mm-theme-black .mm-indexer a,
.mm-menu.mm-theme-black .mm-noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-family: inherit;
}


/* Content inserted with CSS is not read by some screen  */
/* core/public/shared/assets/css/slideshow.css */
.slideshow-wrapper .cycle-paused:after {
	content: '||' !important;
}
