body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
}

.page-wrapper-full {
	color: #333333;
	display: block;
}

#printFooter,
#printHeader {
	display: none;
}

h1 {
	margin-top: 0em;
	margin-bottom: 0.5em;
	font-size: 2.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h2 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 1.875rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	font-size: 1.5rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 1.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-size: 0.9375rem;
	line-height: 1.5em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h6 {
	margin-top: 1.5em;
	margin-bottom: 0.875em;
	font-size: 0.8125rem;
	line-height: 1.5em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.25em;
	border-left: 4px solid rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.1);
	font-size: 1.0625em;
	line-height: 1.5em;
}

.button,
.button.primary,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5em;
	margin-right: 10px;
	margin-bottom: 0.5em;
	padding: 0.5em 1.5em;
	border: 3px solid #001489;
	background-color: #001489;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.main-content-wrap .primary,
.main-content-wrap .secondary,
.main-content-wrap .tertiary {
	color: #fff;
}

.main-content-wrap .primary:hover,
.main-content-wrap .secondary:hover,
.main-content-wrap .tertiary:hover {
	color: #fff;
}

.primary,
.secondary,
.tertiary,
button[type="submit"],
#jobs_module.cms_form .cms_form_button {
	padding: 0.5em 1.5em !important;
	border: 3px solid #001489 !important;
}

.button:hover {
	border-color: #ba0c2f;
	background-color: #ba0c2f;
	color: #fff;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover,
#jobs_module.cms_form .cms_form_button:hover {
	border-color: #ba0c2f !important;
	background-color: #ba0c2f !important;
	color: #fff;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	border: 3px solid transparent !important;
	background-color: #28313b;
	color: #fff;
}

.button.secondary:hover {
	border-color: transparent;
	background-color: #585c5f;
	color: #fff;
}

.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #585c5f !important;
	border-color: transparent !important;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	color: #fff;
	border-color: #373a3d !important;
	background-color: #373a3d;
}

.button.tertiary:hover {
	border-color: #586879;
	background-color: #586879;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	border-color: #586879 !important;
	background-color: #586879 !important;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2rem;
	height: 100%;
	min-height: 2rem;
	margin-top: 0em;
	margin-right: 0px;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	background-color: #ba0c2f;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.button.slider-button {
	width: auto;
	margin-top: 1em;
	margin-bottom: 0.25em;
	padding: 0.5em 1em;
	float: left;
	border: 2px none #aaa;
	background-color: #ba0c2f;
	box-shadow: none;
	color: #fff;
	font-size: 0.85rem;
	text-align: center;
}

.button.slider-button:hover {
	border-color: #ab1b20;
	background-color: #001489;
	box-shadow: none;
	color: #fff;
}

.container.footer {
	display: none;
}

.nav-section {
	background-color: transparent;
	color: #005687;
	position: relative;
}

.nav-container {
	position: relative;
	height: 40px;
	max-width: 100%;
	background-image: -webkit-linear-gradient(270deg, #005687, #005687);
	background-image: linear-gradient(180deg, #005687, #005687);
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 1rem 1.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.5), 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
}

.nav-link.w--current {
	display: none;
	height: 40px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -3px 0 0 #fff, 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
	text-transform: uppercase;
}

.nav-link.w--current:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px solid rgba(20, 25, 82, 0.85);
	background-image: -webkit-linear-gradient(270deg, #001489, #001489);
	background-image: linear-gradient(180deg, #001489, #001489);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5em;
	margin-bottom: 0.35em;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom-style: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.logo:hover {
	color: #fff;
}

.logo.w--current {
	margin-top: 0.5em;
	margin-bottom: 0.35em;
	margin-left: 0em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.footer-section {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1250px;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.row.footer-row.top {
	display: none;
}

.column {
	width: 100%;
	padding-bottom: 0.25rem;
	padding-left: 0px;
	float: left;
	background-image: none;
}

.image-wrapper {
	padding-top: 55%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
}

.logo-image {
	width: auto;
	height: 5rem;
	float: left;
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #222527;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	color: #cdcece;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

.footer-column-wrap {
	max-width: 62%;
	margin-bottom: 1em;
	padding-right: 1rem;
	padding-left: 1rem;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap.quicklinks {
	display: none;
}

.footer-column-wrap._1 {
	max-width: 25%;
	padding-left: 0.8rem;
}

.footer-column-wrap._2 {
	width: 210px;
	max-width: 25%;
	padding-left: 0.8rem;
}

.footer-column-wrap._3 {
	max-width: 35%;
	padding-left: 0.8rem;
}

.footer-column-title {
	margin-top: 0.5em;
	color: #fff;
	font-size: 1.125rem;
	text-transform: uppercase;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	text-transform: none;
}

.header-contents-wrap {
	position: relative;
	background-color: #f2f3f4;
	color: #2a317d;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 1em 0.125rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	height: 50px;
	background-color: #001489;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.header-search-field {
	width: 487px;
	height: 2rem;
	margin-bottom: 0em;
	padding-right: 3rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #222;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	border: 0px none transparent;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #167c28;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #ba0c2f;
	color: #fff;
}

.secondary-nav-list {
	display: none;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.inside-row {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.left-nav-column {
	width: 22%;
	padding-top: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	background-image: none;
}

.main-content-column {
	width: 78%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: #fff;
	box-shadow: none;
	font-family: Ubuntu, Helvetica, sans-serif;
}

.main-content-column.right-sidebar-page {
	width: 75%;
}

.inside-row-nested {
	padding-bottom: 2rem;
	background-color: transparent;
}

.right-sidebar-column {
	margin-top: 2em;
	border-left: 3px solid hsla(0, 0%, 50%, 0.1);
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-list-item {
	margin-bottom: 0em;
	box-shadow: none;
}

.left-nav-list-link {
	display: block;
	width: 100%;
	min-height: 46px;
	padding: 0.7rem 1.5rem 0.5rem 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 2px none rgba(0, 0, 0, 0.08);
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/side-nav-arrow-06-06_transp.svg');
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	color: #000;
	font-size: 0.95rem;
	line-height: 1.4em;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-list-link.selected span {
	color: #fff;
	background-color: #001489;
}

.left-nav-list-link.selected:hover span {
	color: #fff;
	background-color: #28317e;
}


.left-nav-list-link:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02_1.png');
	background-position: 100% 50%;
	background-size: 1065px;
	background-repeat: no-repeat;
	color: #fff;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02_1.png');
	background-position: 100% 50%;
	background-size: 1065px;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #fff;
	font-weight: 700;
	text-decoration: none;
}

.left-nav-list-link.selected:hover,
.left-nav-list-link.w--current:hover {
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02_2.png');
	background-position: 100% 50%;
	background-size: 1065px;
}

.left-nav-list-link.section-title {
	padding-top: 0.85em;
	border-bottom: 3px solid #2a317d;
	color: #001489;
	font-size: 1.35rem;
	text-transform: uppercase;
}

.left-nav-list-link.section-title:hover {
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #ba0c2f;
}

.left-nav-list-link.section-title.w--current {
	background-image: none;
}

.left-nav-list-link.grandchild-link {
	padding-left: 2.15rem;
	font-size: 0.9rem;
}

.left-nav-list-link.greatgrandchild-link {
	padding-left: 3rem;
	font-size: 0.83rem;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.left-nav-list-link.greatgrandchild-link ~ .left-nav-nested-list a.greatgrandchild-link {
	padding-left: 3.75rem;
	font-size: 0.79rem;
}

.sidebar-section-wrap {
	position: relative;
	margin-right: 1rem;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4,
.right-sidebar-column h5,
.right-sidebar-column h6,
.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-size: 1.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
	font-family: 'Open Sans', sans-serif;
	color: #444;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	box-shadow: none;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.slider-section {
	position: relative;
}

.slider {
	display: block;
	height: 450px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	bottom: -340px;
	width: 45px;
	height: 50px;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	background-color: #9ea2a2;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 2rem;
}

.slider-arrow:hover {
	background-color: #ba0c2f;
	background-image: none;
	opacity: 1;
}

.slider-arrow.right {
	border-radius: 25px 0px 0px 25px;
	background-image: -webkit-linear-gradient(270deg, #9ea2a2, #9ea2a2);
	background-image: linear-gradient(180deg, #9ea2a2, #9ea2a2);
	opacity: 1;
}

.slider-arrow.right:hover {
	background-image: -webkit-linear-gradient(270deg, #ba0c2f, #ba0c2f);
	background-image: linear-gradient(180deg, #ba0c2f, #ba0c2f);
	opacity: 1;
}

.slider-indicator {
	z-index: 2;
	height: 2.5rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.6rem;
}

.main-section {
	background-color: #9ea2a2;
	display: block;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-image: -webkit-linear-gradient(111deg, transparent 36%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png'), url('/20170713122722/local/public/shared/assets/images/websites/slide-2.png');
	background-image: linear-gradient(339deg, transparent 36%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png'), url('/20170713122722/local/public/shared/assets/images/websites/slide-2.png');
	background-position: 0px 0px, 0px 0px, 50% 66%, 0% 0px;
	background-size: auto, auto, cover, cover;
	background-repeat: repeat, repeat, no-repeat, no-repeat;
}

.slider-image.slide-2 {
	background-image: -webkit-linear-gradient(111deg, transparent 35%, #fff 75%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.25)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
	background-image: linear-gradient(339deg, transparent 35%, #fff 75%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.25)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
	background-position: 0px 0px, 0px 0px, 0% 65%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.slider-image.slide-3 {
	background-image: -webkit-linear-gradient(111deg, transparent 33%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
	background-image: linear-gradient(339deg, transparent 33%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
	background-position: 0px 0px, 0px 0px, 0% 53%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, repeat;
}

.slider-caption-wrap {
	position: relative;
	top: 99%;
	z-index: 1000;
	display: none;
	overflow: hidden;
	width: 100%;
	max-height: 285px;
	min-height: 180px;
	margin-right: 0%;
	margin-left: 0%;
	padding-top: 0.75rem;
	padding-bottom: 1.5rem;
	background-image: -webkit-linear-gradient(270deg, hsla(210, 8%, 95%, 0.9), hsla(210, 8%, 95%, 0.9));
	background-image: linear-gradient(180deg, hsla(210, 8%, 95%, 0.9), hsla(210, 8%, 95%, 0.9));
	-webkit-transform: translate(0px, -63%);
	-ms-transform: translate(0px, -63%);
	transform: translate(0px, -63%);
	color: #fff;
	text-align: center;
}

.inside-page-header-content-wrap {
	padding: 0.5rem 1.5rem 0.25em;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
	background-color: #fff;
}

.inside-page-header-content-wrap.entry {
	border-bottom: 3px solid hsla(0, 0%, 50%, 0.1);
	background-color: #fff;
}

.inside-page-header-section {
	position: relative;
	display: none;
	min-height: 120px;
	background-color: #373a3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 100%;
	margin-top: -50px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, #f2f3f4, #f2f3f4);
}

.main-content-wrap {
	padding-left: 1.5rem;
}

.main-content-wrap.nested-content-wrap {
	padding: 2rem;
	background-color: #fff;
	box-shadow: none;
	min-height: 400px;
}

.main-content-wrap.nested-content-wrap.full {
	padding-top: 2rem;
	padding-right: 8rem;
	padding-left: 8rem;
}

.header-menu-inside-wrap {
	display: block;
	height: 40px;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.intro_text_style,
.intro-text-style {
	margin-bottom: 1em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #444;
	font-size: 1.1875em;
	line-height: 1.5em;
	font-weight: 600;
}

.slider-arrow-icon {
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.news-content-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1.25rem;
	padding-bottom: 1.35rem;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.85), hsla(0, 0%, 100%, 0.85)), -webkit-linear-gradient(270deg, #fff, #fff), -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.85), hsla(0, 0%, 100%, 0.85)), linear-gradient(180deg, #fff, #fff), linear-gradient(180deg, #f2f3f4, #f2f3f4);
	box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.15);
}

.slider-caption-title {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2.1rem;
	font-weight: 600;
	text-align: left;
}

.footer-links-list {
	margin-top: -0.25em;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item {
	margin-bottom: 1px;
	box-shadow: none;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	opacity: 0.55;
}

.footer-social-icon-link:hover {
	opacity: 1;
}

.footer-social-icon-image {
	width: 28px;
	height: 28px;
	padding: 0px;
}

.left-nav-list-content-wrap {
	overflow: hidden;
	padding-top: 4.85rem;
	padding-bottom: 1.5em;
	border-right: 1px none rgba(0, 0, 0, 0.15);
	background-color: hsla(180, 2%, 63%, 0.25);
}

.dropdown-list-section-title {
	margin-bottom: 0rem;
	padding: 0.5rem;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	line-height: 1.25em;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.footer-link {
	color: #9ea1a1;
	text-decoration: none;
}

.footer-link:hover {
	color: #fff;
}

.footer-link.footer-list-item-link {
	display: block;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	color: #cdcece;
	text-decoration: underline;
	text-transform: capitalize;
}

.footer-link.footer-list-item-link:hover {
	color: #fff;
}

.footer-link.footer-list-item-link.hyperlink {
	text-decoration: underline;
}

.right-sidebar-column a,
.main-content-wrap a,
.link {
	text-decoration: none;
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #001489;
	cursor: pointer;
}

.right-sidebar-column a:hover,
.main-content-wrap a:hover,
.link:hover {
	text-decoration: none;
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
	color: #28313b;
}

.right-sidebar-column a.primary:hover,
.main-content-wrap a.primary:hover,
.left-sidebar-custom a.primary:hover {
	color: #fff !important;
	text-decoration: none;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.breadcrumb-link {
	color: #001489;
	cursor: pointer;
}

.link.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #9ea2a2;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.table-style-embed {
	margin-top: 0.5rem;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid rgba(20, 25, 82, 0.85);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: #0b358e;
	box-shadow: 0 -3px 0 0 #ba0c2f;
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-wrap:hover .nav-drop-toggle {
	background-color: #0e3c9c;
	background-image: none;
	box-shadow: 0 -3px 0 0 #ba0c2f;
	color: #fff;
}

.nav-drop-icon {
	margin-right: 2rem;
	color: #cecccc;
	font-size: 0.75em;
	line-height: 1.25em;
}

.nav-drop-list {
	display: none;
	left: 0px;
	top: 100%;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #33383b;
	color: #fff;
}

.nav-drop-list.w--open {
	display: block;
	padding: 0.5em 0.5rem;
}

.nav-drop-list-link {
	display: block;
	padding: 0.5em 1.5em 0.5em 0.5rem;
	color: #cdcece;
	font-size: 0.875rem;
	font-weight: 600;
	text-decoration: none;
}

.nav-drop-list-link:hover {
	background-color: #9ea1a1;
	color: #000;
	font-weight: 600;
}

.nav-drop-list-link.w--current {
	background-color: #9ea1a1;
	color: #000;
	font-weight: 600;
}

.nav-drop-toggle-link {
	width: 100%;
	height: 50px;
	padding: 0.85rem 3rem 0.85rem 1rem;
	background-image: none;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
}

span.nav-drop-toggle-link {
	cursor: default;
}

.nav-drop-wrap:not(.has-dropdown) .nav-drop-toggle-link {
	padding-right: 1rem;
}

.nav-drop-toggle-link:hover {
	background-image: none;
	color: #fff;
}

.nav-drop-toggle-link.selected,
.nav-drop-toggle-link.w--current {
	background-color: #0e3c9c;
	background-image: none;
	box-shadow: 0 -3px 0 0 #ba0c2f;
}

.nav-drop-toggle-link.selected:hover,
.nav-drop-toggle-link.w--current:hover {
	background-color: #0e3c9c;
	background-image: none;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-image: -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, #f2f3f4, #f2f3f4);
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.71rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}

.secondary-header-item-cta-link {
	flex-grow: 1;
	height: 2rem;
	margin-left: 0.85rem;
	padding: 0.5em 3em 0.5em 1.5em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: #ba0c2f;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/keyboard-right-arrow-button-1.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: none;
	text-decoration: none;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
}

.donate-image {
	width: 16px;
	margin: -1px 8px 0px -9px;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 0.85rem;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: rgba(0, 0, 0, 0.09);
}

.slider-caption-title-wrap {
	margin-bottom: 0.5rem;
	padding-right: 10px;
	padding-left: 0px;
	text-align: left;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.footer-social-icons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.footer-contact-label {
	display: inline-block;
	min-width: 64px;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.special-notice-alert-box {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: none;
}

.special-notice-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-top: 4px;
	padding-bottom: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 3px solid #dbc368;
	background-color: #fff;
	color: #151618;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-transform: none;
}

.special-notice-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #ba0c2f;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
}

.special-notice-text {
	display: inline-block;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.8rem;
	font-weight: 700;
	text-transform: none;
}

.special-notice-text p {
	margin-bottom: 0;
}

.special-notice-text a {
	color: #0000ee;
}

.news-container {
	display: block;
	width: 98%;
	margin-right: auto;
	margin-left: auto;
}

.news-content-wrapper {
	display: block;
}

.find-your-squadron-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #001489;
}

.squadron-container {
	display: block;
	width: 225px;
	margin-right: 15px;
	padding-top: 20px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.squadron-container.zipcode {
	width: 355px;
	margin-left: 25px;
	padding-top: 15px;
	padding-bottom: 5px;
}

.i-am-a-text {
	display: block;
	width: auto;
	padding: 0.2rem 11px 0.2rem 10px;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	color: #f2f3f4;
	font-size: 0.85rem;
	text-align: left;
}

.go-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42px;
	height: 34.75px;
	margin-right: 5px;
	margin-left: -3px;
	padding: 0.25rem 0.55rem 0.25rem 0.35rem;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-left: 3px none #beddeb;
	border-radius: 0px 50px 50px 0px;
	background-color: #ba0c2f;
	background-image: none;
	color: #f2f3f4;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
}

.go-button:hover {
	background-color: #6b7070;
	background-image: none;
	color: #f2f3f4;
}

.zip-text {
	width: 125px;
	height: 34px;
	margin-bottom: 0px;
	padding: 0.3rem 6px;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #222;
	font-size: 0.85rem;
	line-height: 1.5em;
	text-align: center;
	float: left;
}

.zip-text:focus {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.zipcode-form-block {
	margin-bottom: 0px;
}

.zip-code-text-field {
	display: block;
}

.news-image {
	width: 100%;
	height: auto;
}

.news-caption-text {
	margin-top: 0.75rem;
	margin-bottom: 0em;
	color: #28313b;
	font-size: 0.9rem;
	line-height: 1.5em;
}

.news-caption-text:hover {
	color: #28313b;
}

.donate-buttons-section {
	display: block;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 30px;
	padding-bottom: 40px;
	background-color: #f1f1f1;
}

.donate-button-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 30px;
	background-color: #ba0c2f;
}

.donate-button-container:hover {
	background-color: #001489;
}

.donate-text {
	margin-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
}

.donate-text strong {
	text-transform: uppercase;
}

.donate-text p {
	margin-bottom: 0;
	display: inline-block;
}

.content-box-section {
	position: static;
	top: 575px;
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-top: 12rem;
	margin-right: auto;
	margin-left: auto;
	background-image: none;
}

.content-box-section-container {
	width: 98%;
}

.cb {
	width: 33.33%;
	padding: 1rem;
	float: left;
}

.cb-wrapper {
	padding-bottom: 4rem;
}

.custom-link.cb {
	text-decoration: none;
}

.cb-content {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: 1.5rem;
	float: none;
	border-top: 4px solid #ba0c2f;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	-webkit-transition: opacity 300ms ease, all 300ms ease;
	transition: opacity 300ms ease, all 300ms ease;
	position: relative;
}

.cb-content:hover {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff), -webkit-linear-gradient(270deg, hsla(210, 8%, 95%, 0.54), hsla(210, 8%, 95%, 0.54));
	background-image: linear-gradient(180deg, #fff, #fff), linear-gradient(180deg, hsla(210, 8%, 95%, 0.54), hsla(210, 8%, 95%, 0.54));
	box-shadow: 1px 0 12px 2px rgba(0, 0, 0, 0.2);
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.cb-image {
	width: 55%;
	padding-left: 1.35rem;
	float: right;
	background-image: none;
}

.cb-text-heading {
	display: inline-block;
	width: 45%;
	padding-top: 0px;
	padding-right: 0.35rem;
	float: left;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2rem;
	font-weight: 600;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.custom-link:hover .cb-text-heading {
	color: #28313b;
}

.column-wrapper {
	width: 33.33%;
	padding-top: 0.35rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	float: left;
}

.social-media-section {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #f1f1f1;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.15);
}

.social-media-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.social-image {
	width: auto;
	height: auto;
	padding: 5px;
}

.social-image.twitter {
	margin-top: 3px;
	padding: 5px;
}

.social-image.facebook {
	padding: 5px;
}

.social-image.linkedin {
	padding: 5px;
}

.social-image-link {
	width: 55px;
	height: 55px;
	padding: 8px;
	border-style: solid;
	border-width: 2px;
	border-color: hsla(180, 2%, 63%, 0.29);
	border-radius: 30px;
	background-color: #fff;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
}

.social-image-link:hover {
	border-color: #9ea1a1;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5));
}

.donate-button {
	width: 30px;
	height: 30px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/circle-arrow-02.png');
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: repeat-y;
}

.body {
	background-color: #beddeb;
	background-image: none;
	background-position: 0px 0px;
}

.page-wrapper-full {
	overflow-x: hidden;
}

.paragraph {
	display: none;
}

.footer-paragraph {
	color: #cdcece;
	display: block;
}

.footer-paragraph-link {
	color: #cdcece;
}

.footer-paragraph-link:hover {
	color: #fff;
}


.footer-paragraph.phone-numbers {
	display: none;
}

.email-us-paragraph {
	display: none;
}

.footer-container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 1.75rem 1rem;
}

.slide-caption-para {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	color: #151618;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.slide-caption-para p:last-of-type {
	margin-bottom: 0;
}

.donate-link {
	display: block;
	width: 400px;
	margin-right: auto;
	margin-left: auto;
	text-decoration: none;
}

.share-icon {
	width: 20px;
	height: 20px;
}

.site-share-wrapper {
	position: relative;
}

.site-share-wrapper .nav-drop-list {
	right: 0;
	left: auto;
	width: 180px;
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-block:hover {
	background-color: #0e3c9c;
	box-shadow: 0 -3px 0 0 #ba0c2f;
}

.link-block.new {
	display: none;
}

.select-cadet-dropdown {
	width: 125px;
	height: 34px;
	padding-top: 0.3rem;
	padding-right: 20px;
	padding-bottom: 0.3rem;
	border: 1px solid #fff;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #777;
	float: left;
}

.connect-with-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 20px;
	padding-left: 12px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02.png');
	background-position: 0px 0px;
	background-size: cover;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #f2f3f4;
	font-weight: 600;
	text-transform: none;
}

.connect-with-us.mobile-portrait {
	padding-right: 45px;
	padding-left: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02_1.png');
	background-position: 0px 0px;
	background-size: cover;
	color: #fff;
}

.bottom-footer-container {
	display: block;
	max-width: 1225px;
	margin-right: auto;
	margin-left: auto;
}

.inside-slider {
	height: 450px;
}

.inside-slideshow-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.inside-slider .w-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.landing-page-title-wrapper {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	background-color: #ba0c2f;
}

.h1-heading {
	margin-bottom: 0em;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.25px;
	text-transform: uppercase;
}

.right-sidebar {
	padding-right: 0.5px;
}

.h1 {
	margin-bottom: 0.25em;
	color: #005687;
}

.h2,
.main-content-wrap h2,
.left-sidebar-custom h2 {
	margin-top: 0em;
	margin-bottom: 0.5em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h3,
.main-content-wrap h3,
.left-sidebar-custom h3 {
	margin-top: 0.75em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h4,
.main-content-wrap h4,
.left-sidebar-custom h4 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h5,
.main-content-wrap h5,
.left-sidebar-custom h5 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h6,
.main-content-wrap h6,
.left-sidebar-custom h6 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.slide-nav {
	font-size: 0.6rem;
}

.nav-dropdown-wrap {
	width: 100%;
}

.nav-dropdown-toggle {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 5px 0px 6px;
	color: #fff;
}

.icon-design {
	margin-right: 0.5rem;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #cdcece;
	font-size: 0.75em;
}

.nav-dropdown-list-right {
	display: none;
	left: 180px;
	top: -8px;
	background-image: -webkit-linear-gradient(270deg, #1f2022, #1f2022);
	background-image: linear-gradient(180deg, #1f2022, #1f2022);
	left: 100%;
	background-image: -webkit-linear-gradient(270deg, #292d30, #292d30);
	background-image: linear-gradient(180deg, #292d30, #292d30);
}

@media (max-width: 1700px) {
	.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right {
		left: auto;
		right: 100%;
	}
}

.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right {
	width: 250px;
	min-width: 0;
}

.nav-dropdown-list-right.w--open {
	padding: 0.75rem 0.5rem;
	display: block;
}

.nav-dropdown-link {
	width: 100%;
	padding: 0.4rem 1.5rem 0.4rem 0.5rem;
	color: #cdcece;
	font-size: 0.875rem;
	font-weight: 600;
}

.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right .nav-dropdown-link {
	white-space: normal;
}

.nav-dropdown-link:hover {
	background-color: #9ea1a1;
	color: #000;
}

.list-item-2 {
	margin-bottom: 0px;
}

.current-breadcrumb {
	color: #001489;
	font-weight: 500;
}

.left-nav-divider {
	width: 269px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.1);
}

.right-sidebar-column blockquote,
.main-content-wrap blockquote,
.left-sidebar-custom blockquote,
.blockquote {
	border-left-color: #ba0c2f;
	background-color: #f0f2f3;
}

.news-blue-text {
	margin-top: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 0px;
	letter-spacing: 0;
	line-height: 1.5rem;
}

.news-blue-text:hover {
	color: #ba0c2f;
}

.middle-footer-div {
	background-color: #373a3d;
	display: block;
}

.news-section {
	padding-top: 0;
	padding-bottom: 40px;
	background-color: #f1f1f1;
}

.cap-wrapper {
	display: block;
	width: 1250px;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.75rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cap-wrapper._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: 1300px;
	padding-top: 3rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.div-block-2 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	text-align: left;
}

.main-content {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.caption-wrap {
	width: 50%;
}

.right-sidebar-column h1,
.main-content-wrap h1,
.left-sidebar-custom h1,
.heading-7 {
	color: #001489;
	display: block;
}

.link-block-2 {
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
}

.content-box-section-new {
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.13), transparent 20%, transparent 99%), -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.13), transparent 20%, transparent 99%), linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
	background-position: 0px 0px, 0px 0px, 50% 0px, 0px 0px;
	background-size: auto, auto, cover, auto;
	background-repeat: repeat, repeat, no-repeat, repeat;
}

.learn-more-heading {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 4rem;
	padding-bottom: 4rem;
	box-shadow: none;
	color: transparent;
	font-size: 3rem;
	text-align: center;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000008;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

.clearBoth {
	clear: both;
}

.left-nav-reveal-button-wrap {
	display: none;
	padding: 0.75em 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px solid #d9e3ec;
	border-bottom: 1px solid #d9e3ec;
	font-family: Khand, sans-serif;
	color: #001489;
	font-size: 1.3em;
	cursor: pointer;
	font-weight: bold;
}

.left-nav-reveal-button-text {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding-top: 0.125em;
}

.left-nav-reveal-button-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 6px 6px 5px;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border: 2px solid #ba0c2f;
	border-radius: 2em;
	background-color: #ba0c2f;
	opacity: 1;
}

.left-nav-list-link {
	min-height: 41px;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	.button.header-search-button {
		z-index: 1000;
		width: 5rem;
		height: 60px;
		min-height: auto;
		border-style: none;
		background-color: #82b3cf;
		font-size: 1.125rem;
	}

	.button.slider-button {
		width: auto;
		float: none;
		border-style: none;
		background-color: #ab1b20;
		color: #f2f3f4;
	}

	.container {
		max-width: 100%;
	}

	.nav-section {
		padding-top: 0px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 60px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		background-color: #001489;
		background-image: none;
		color: #fff;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		line-height: 1.375rem;
		text-align: left;
	}

	.nav-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		display: block;
		height: 58px;
		padding-top: 1em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #ba0c2f;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		margin-top: 60px;
		padding-top: 5px;
		padding-bottom: 100vh;
		border-left-style: none;
		border-left-color: #ba0c2f;
		background-color: #0e3c9c;
		background-image: none;
		text-align: left;
	}

	.logo {
		margin-left: 0.75em;
		font-size: 1.375rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		width: 100%;
		margin-bottom: 0.5rem;
		padding-top: 10px;
		padding-right: 0px;
		padding-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo-image {
		width: auto;
		height: 4.5rem;
	}

	.menu-button {
		position: absolute;
		left: 0px;
		top: 0px;
		bottom: 0px;
		height: 60px;
		padding: 0px 1.5em 0px 1em;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: rgba(0, 4, 53, 0.85);
	}

	.menu-button.w--open {
		background-color: transparent;
		text-transform: uppercase;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 2rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 1rem;
		margin-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-top: 0rem;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.footer-column-wrap._1 {
		max-width: 28%;
	}

	.footer-column-wrap._2 {
		width: 280px;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.header-menu-wrap {
		position: absolute;
		width: 100%;
		height: 60px;
	}

	.form-field.header-search-field {
		display: block;
		width: 100%;
		height: 60px;
		padding-right: 0rem;
		padding-left: 1.5rem;
		float: none;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		border-bottom-style: solid;
		border-bottom-width: 3px;
		background-color: #005687;
		color: #c7c7c7;
		font-size: 0.9375rem;
		line-height: 1.25em;
	}

	.form-field.header-search-field:focus {
		width: 100%;
		border-bottom: 3px solid #beddeb;
		background-color: #fff;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.main-content-column {
		width: 100%;
	}

	.main-content-column.right-sidebar-page {
		width: auto;
	}

	.inside-row-nested {
		padding-bottom: 0rem;
	}

	.right-sidebar-column {
		margin-top: 0em;
		padding-right: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
		border-top: 3px solid #9ea1a1;
		background-color: #f1f1f1;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-bottom: 2em;
		margin-left: 0em;
		padding-top: 1em;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.slider {
		height: 500px;
	}

	.slider-arrow {
		bottom: -380px;
		width: 42px;
		font-size: 1.75rem;
	}

	.slider-arrow.entry {
		bottom: -380px;
	}

	.slider-indicator {
		height: 3rem;
		padding-top: 0.5rem;
		color: #ab1b20;
	}

	.slider-image {
		background-image: -webkit-linear-gradient(111deg, transparent 36%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-image: linear-gradient(339deg, transparent 36%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-position: 0px 0px, 0px 0px, 100% 0px;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-2 {
		height: 505px;
		background-image: -webkit-linear-gradient(103deg, transparent 28%, #fff), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.25)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
		background-image: linear-gradient(347deg, transparent 28%, #fff), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.25)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
		background-position: 0px 0px, 0px 0px, 100% 122%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-3 {
		background-image: -webkit-linear-gradient(111deg, transparent 33%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
		background-image: linear-gradient(339deg, transparent 33%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
		background-position: 0px 0px, 0px 0px, 50% 50%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-caption-wrap {
		top: 89%;
		width: 100%;
		max-height: 250px;
		min-height: 200px;
		padding: 1rem 3.5rem 5rem;
		background-image: -webkit-linear-gradient(270deg, #beddeb, #beddeb);
		background-image: linear-gradient(180deg, #beddeb, #beddeb);
		box-shadow: 0 0 33px 0 #5b636b;
	}

	.inside-page-header-content-wrap {
		padding-top: 0.25rem;
		background-image: -webkit-linear-gradient(270deg, #f1f1f1, #f1f1f1);
		background-image: linear-gradient(180deg, #f1f1f1, #f1f1f1);
	}

	.inside-page-header-content-wrap.entry {
		padding-top: 0.25rem;
		background-image: -webkit-linear-gradient(270deg, #f1f1f1, #f1f1f1);
		background-image: linear-gradient(180deg, #f1f1f1, #f1f1f1);
	}

	.inside-page-header-section {
		position: relative;
		display: block;
		width: 100%;
		height: 120px;
		min-height: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
		opacity: 1;
	}

	.inside-content-beckground {
		max-width: 100%;
		margin-top: 0px;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.main-content-wrap.nested-content-wrap {
		width: 100%;
		margin-right: 0em;
		padding-top: 1.5rem;
		padding-right: 2rem;
		padding-bottom: 1.5rem;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding: 1.5rem 2rem;
	}

	.menu-button-icon {
		height: 60px;
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #fff;
		font-size: 2rem;
		line-height: 4rem;
	}

	.menu-button-text {
		height: 60px;
		margin-left: 0.125rem;
		float: left;
		color: #cdcece;
		font-size: 0.75rem;
		line-height: 4rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		display: block;
		height: 60px;
		max-width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0.5rem;
	}

	.news-content-section {
		padding-bottom: 1rem;
	}

	.slider-caption-title {
		width: 100%;
		font-size: 1.4em;
		line-height: 1.8rem;
	}

	.footer-social-icon-link {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.footer-social-icon-image {
		width: 32px;
		height: 32px;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.table-style-embed {
		margin-bottom: 1rem;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.two-part-drop-list-section {
		padding: 2em 1rem 1em;
		border-bottom: 1px solid #fff;
		color: hsla(0, 0%, 100%, 0.5);
		font-size: 0.875rem;
		line-height: 1.25em;
		font-weight: 600;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.header-content-right-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.secondary-header-item-cta-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.8125rem;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem;
	}

	.header-search-form {
		display: block;
		width: 100%;
		min-width: 768px;
		background-color: #fff;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
		width: 100%;
	}

	.footer-social-icons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.find-your-squadron-section {
		background-image: none;
	}

	.squadron-container {
		width: 250px;
		margin-right: 0px;
	}

	.squadron-container.zipcode {
		width: 350px;
		margin-left: 0px;
		padding-top: 20px;
		padding-bottom: 10px;
	}

	.i-am-a-text {
		padding-right: 5px;
		padding-left: 5px;
	}

	.go-button {
		width: 36px;
		height: 34px;
		margin-left: -3px;
		padding-left: 0.45rem;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	.zip-text {
		padding-right: 7px;
		padding-left: 0px;
		float: left;
	}

	.zipcode-form-block {
		float: left;
	}

	.zip-code-text-field {
		margin-left: 5px;
		float: left;
	}

	.news-image {
		height: auto;
	}

	.donate-buttons-section {
		padding-top: 40px;
		box-shadow: inset 0 1px 14px 1px hsla(0, 0%, 56%, 0.5);
	}

	.donate-button-container {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.content-box-section {
		top: 300px;
		margin-top: 3rem;
		background-image: -webkit-linear-gradient(270deg, transparent, hsla(180, 2%, 61%, 0.26) 26%, hsla(180, 2%, 63%, 0.27) 27%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, hsla(180, 2%, 61%, 0.26) 26%, hsla(180, 2%, 63%, 0.27) 27%, #9ea2a2);
	}

	.cb {
		width: 50%;
	}

	.cb.left {
		padding-left: 1.5rem;
	}

	.cb.right {
		padding-right: 1rem;
		padding-left: 1.5rem;
	}

	.cb-wrapper {
		padding-top: 2rem;
		background-image: -webkit-linear-gradient(270deg, transparent, #9ea2a2 14%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, #9ea2a2 14%, #9ea2a2);
	}

	.cb-content {
		padding-left: 1.5rem;
	}

	.cb-text-heading {
		font-size: 1.6rem;
		line-height: 1.9rem;
	}

	.column-wrapper {
		padding-top: 0rem;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.social-image.twitter {
		margin-top: 3px;
	}

	.slide-caption-para {
		width: 100%;
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.link-block {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 15px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.link-block.new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 5rem;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		background-color: transparent;
		background-image: none;
	}

	.select-cadet-dropdown {
		padding-left: 7px;
	}

	.connect-with-us.mobile-portrait {
		padding-right: 40px;
		padding-left: 25px;
	}

	.inside-slider {
		height: 505px;
	}

	.landing-page-title-wrapper {
		padding-left: 2rem;
		background-color: #ba0c2f;
		background-image: none;
	}

	.h1-heading {
		margin-bottom: 0em;
		font-size: 1.9rem;
	}

	.right-sidebar {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.h2 {
		font-size: 1.65rem;
	}

	.h3 {
		font-size: 1.4rem;
	}

	.h4 {
		font-size: 1.15rem;
	}

	.header-search-field {
		height: 60px;
		margin-bottom: 0px;
		padding: 0.75em 1em;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 1.0625rem;
		border-radius: 0;
	}

	.mobile-search-go-button {
		width: 20%;
		height: 60px;
		padding: 0px;
		background-color: #0e3c9c;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/circle-arrow-02_1.png');
		background-position: 50% 50%;
		background-size: 35px 35px;
		background-repeat: no-repeat;
		color: transparent;
		border: 0 none;
		border-radius: 0;
	}

	.mobile-search-button {
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		width: 5rem;
		height: 60px;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/search.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.mobile-search-button:hover {
		background-color: rgba(0, 4, 53, 0.85);
	}

	.mobile-search-dd-wrap {
		position: relative;
		display: none;
		height: 0px;
		margin-top: 60px;
	}

	.mobile-search-form-block {
		margin-bottom: 0px;
		display: none;
	}

	.mobile-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.mobile-search-field {
		width: 80%;
		height: 60px;
		margin-bottom: 0px;
		font-size: 1.1rem;
	}

	.mobile-share-wrapper {
		position: relative;
		margin-left: 11rem;
	}

	.news-section {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.cap-wrapper._2 {
		width: 100%;
		padding-top: 2rem;
		padding-right: 2rem;
	}

	.caption-wrap {
		width: 100%;
		max-width: 600px;
	}

	.content-box-section-new {
		height: auto;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp');
		background-position: 0px 0px;
		background-size: contain;
	}

	.learn-more-heading {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
		box-shadow: none;
		font-size: 2rem;
	}

	.left-nav-column {
		display: block !important;
		width: 100%;
	}

	.left-nav-list-content-wrap {
		padding-top: 0px;
	}

	.left-nav-reveal-button-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		background-color: rgba(243, 246, 249, 0.5);
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.left-nav-mobile-reveal {
		overflow: hidden;
		height: 0px;
		background-color: #f3f6f9;
	}

	.left-nav-divider {
		width: 100%;
	}

	.left-nav-list-link.selected,
	.left-nav-list-link.w--current,
	.left-nav-list-link.selected:hover,
	.left-nav-list-link.w--current:hover,
	.left-nav-list-link:hover {
		background-image: none;
		background-color: #001489;
	}

	.left-nav-list-link span,
	.left-nav-list-link.selected span,
	.left-nav-list-link:hover span,
	.left-nav-list-link.selected:hover span {
		background-color: transparent;
	}

	.left-nav-list * {
		box-sizing: border-box;
	}
}

@media (max-width: 767px) {
	.left-nav-reveal-button-wrap {
		padding-right: 20px;
		padding-left: 12px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.slider-button {
		box-shadow: none;
	}

	.logo {
		padding-left: 0px;
	}

	.row.footer-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		padding-top: 10px;
	}

	.logo-image {
		width: auto;
		height: 3rem;
	}

	.footer-column-wrap {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.footer-column-wrap._1 {
		max-width: 100%;
	}

	.footer-column-wrap._2 {
		max-width: 100%;
	}

	.footer-column-wrap._3 {
		max-width: 100%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0em;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.slider {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-mask {
		width: 100%;
		height: auto;
	}

	.slider-arrow {
		bottom: -75%;
		height: 50px;
	}

	.slider-arrow.right {
		bottom: -75%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-arrow.right.entry {
		bottom: -75%;
	}

	.slider-arrow.entry {
		bottom: -75%;
	}

	.slider-indicator {
		top: 465px;
		bottom: auto;
		margin-top: -2rem;
		font-size: 0.5rem;
	}

	.main-section {
		box-shadow: 0 0 6px 1px #9ea2a2;
	}

	.slider-image {
		position: relative;
		height: 470px;
		background-color: #fff;
		background-image: -webkit-linear-gradient(111deg, transparent 36%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-image: linear-gradient(339deg, transparent 36%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-position: 0px 0px, 0px 0px, 100% 50%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-2 {
		height: 470px;
		background-image: -webkit-linear-gradient(101deg, transparent 43%, #fff), -webkit-linear-gradient(270deg, transparent 14%, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
		background-image: linear-gradient(349deg, transparent 43%, #fff), linear-gradient(180deg, transparent 14%, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
		background-position: 0px 0px, 0px 0px, 100% 0px;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-caption-wrap {
		top: 100%;
		width: 100%;
		height: 100%;
		max-height: 500px;
		margin-right: 0%;
		margin-left: 0%;
		padding: 1rem 4%;
		background-image: -webkit-linear-gradient(270deg, #beddeb, #beddeb);
		background-image: linear-gradient(180deg, #beddeb, #beddeb);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.inside-page-header-section {
		min-height: 80px;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0.5rem;
		font-size: 1.1em;
		line-height: 1.45em;
	}

	.slider-caption-title {
		margin-bottom: 0.25rem;
		font-size: 1.2em;
		line-height: 1.1em;
	}

	.header-container {
		padding-bottom: 10px;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.secondary-header-item-cta-link {
		width: 116px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 1.3em;
		padding-left: 1em;
		background-position: 95% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.donate-image {
		margin: -1px 5px 0px -3px;
	}

	.header-search-form {
		min-width: auto;
	}

	.special-notice-container {
		padding: 10px;
	}

	.special-notice-title {
		font-size: 0.9rem;
		line-height: 1rem;
		text-align: right;
	}

	.special-notice-text {
		font-size: 0.7rem;
		line-height: 1rem;
	}

	.find-your-squadron-section {
		padding-top: 6px;
		padding-bottom: 20px;
		text-align: left;
	}

	.squadron-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 220px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 5px;
		padding-left: 15px;
		float: none;
	}

	.squadron-container.zipcode {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 15px;
		padding-right: 5px;
		padding-left: 5px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.i-am-a-text {
		padding-right: 5px;
		padding-bottom: 0.5rem;
		padding-left: 5px;
		font-size: 1rem;
	}

	.go-button {
		height: 35px;
		margin-right: 2px;
		margin-left: 0px;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		font-size: 0.78rem;
		text-align: center;
	}

	.zip-text {
		width: 125px;
		height: 34px;
		margin-right: 0px;
		padding-right: 4px;
		padding-left: 5px;
		font-size: 0.75rem;
	}

	.donate-button-container {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.content-box-section {
		top: 225px;
		margin-top: 2.5rem;
	}

	.cb {
		width: 100%;
		padding: 0.75rem;
	}

	.cb.left {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.cb.right {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.cb-wrapper {
		padding-top: 0rem;
		background-image: -webkit-linear-gradient(270deg, transparent, #b8b8b8 1%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, #b8b8b8 1%, #9ea2a2);
	}

	.cb-content {
		padding-left: 2.5rem;
	}

	.cb-content:hover {
		-webkit-transform: scale(1.03);
		-ms-transform: scale(1.03);
		transform: scale(1.03);
	}

	.cb-image {
		border-radius: 0px;
	}

	.cb-text-heading {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
		font-size: 2.2rem;
		line-height: 2.7rem;
	}

	.column-wrapper {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.social-media-container {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-image.twitter {
		margin-top: 2px;
	}

	.social-image-link {
		width: 40px;
		height: 40px;
		min-height: 25px;
		min-width: 25px;
		padding: 4px;
		border-radius: 34px;
	}

	.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.slide-caption-para {
		font-size: 0.83rem;
		line-height: 1.25rem;
	}

	.select-cadet-dropdown {
		width: 125px;
		margin-bottom: 0px;
		padding-right: 8px;
		padding-left: 8px;
		font-size: 0.75rem;
	}

	.connect-with-us {
		width: 170px;
		height: 48px;
		padding-right: 15px;
		padding-left: 9px;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02.png');
		background-position: 0px 0px;
		background-size: contain;
		background-repeat: no-repeat;
		font-size: 0.9rem;
	}

	.connect-with-us.mobile-portrait {
		width: 150px;
		margin-right: 5px;
		padding-right: 15px;
		padding-left: 15px;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-official-arrow-02_2.png');
		background-position: 100% 50%;
		background-size: cover;
		font-size: 0.85rem;
	}

	.inside-slider {
		height: 470px;
	}

	.blockquote {
		font-size: 1.05em;
		line-height: 1.45em;
	}

	.paragraph-text {
		font-size: 0.95rem;
		line-height: 1.45em;
	}

	.list-text {
		font-size: 0.95rem;
		line-height: 1.45em;
	}

	.cap-wrapper._2 {
		width: 90%;
	}

	.caption-wrap {
		max-width: 500px;
	}

	.content-box-section-new {
		height: auto;
		background-image: -webkit-linear-gradient(270deg, transparent, #a5a4a4 60%, #a5a4a4 82%, #a5a5a5 84%, #9ea2a2), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, transparent, #a5a4a4 60%, #a5a4a4 82%, #a5a5a5 84%, #9ea2a2), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, contain, auto;
		background-repeat: repeat, no-repeat, repeat;
	}

	.learn-more-heading {
		margin-bottom: 0em;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 5rem;
		border-style: none;
	}

	.button.slider-button {
		margin-bottom: 1rem;
		float: left;
		font-size: 0.75rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.logo {
		margin-right: auto;
		margin-left: auto;
	}

	.row.footer-row {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.logo-image {
		height: 3.5rem;
	}

	.menu-button {
		width: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap._1 {
		padding-left: 0rem;
	}

	.footer-column-wrap._2 {
		padding-left: 0rem;
	}

	.footer-column-wrap._3 {
		padding-left: 0rem;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.header-search-field {
		padding-right: 28%;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.slider-section {
		width: 100%;
		max-width: 479px;
		min-width: 320px;
	}

	.slider {
		width: 100%;
		max-width: none;
	}

	.slider-mask {
		width: 100%;
		height: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.slider-arrow {
		bottom: 30%;
		width: 38px;
		height: 48px;
		max-width: 50px;
		font-size: 1.25rem;
	}

	.slider-arrow.right {
		bottom: 30%;
		width: 38px;
		height: 48px;
	}

	.slider-arrow.right.entry {
		bottom: -50%;
	}

	.slider-arrow.entry {
		bottom: -50%;
	}

	.slider-indicator {
		top: 200px;
	}

	.slider-slide {
		width: 100%;
	}

	.slider-image {
		height: 200px;
		background-image: -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-image: linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-2-new.png');
		background-position: 0px 0px, 100% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slider-image.slide-2 {
		position: relative;
		height: 200px;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/slide-1.png');
		background-position: 100% 0px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slider-image.slide-3 {
		background-image: -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
		background-image: linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20170713122722/local/public/shared/assets/images/websites/slide-3.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slider-caption-wrap {
		display: block;
		width: 100%;
		height: 100%;
		min-width: 320px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
		box-shadow: none;
		top: 0;
	}

	.inside-page-header-content-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.main-content-wrap.nested-content-wrap {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.menu-button-icon {
		display: block;
		float: left;
	}

	.menu-button-text {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.025em;
		line-height: 1.4em;
	}

	.slider-caption-title {
		margin-bottom: 0.35rem;
		font-size: 1.3em;
		line-height: 1.55rem;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.header-container {
		padding-bottom: 0px;
	}

	.special-notice-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.special-notice-title {
		padding-bottom: 6px;
		font-size: 1.1rem;
		line-height: 0.9rem;
		letter-spacing: -0.9px;
	}

	.special-notice-text {
		width: 100%;
		min-width: 300px;
		font-size: 0.8rem;
		line-height: 0.9rem;
		text-align: center;
	}

	.find-your-squadron-section {
		display: block;
		margin-bottom: 0px;
		padding-bottom: 2.5rem;
		background-image: none;
		text-align: center;
	}

	.squadron-container {
		display: inline-block;
		width: 240px;
		margin-top: 10px;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.squadron-container.zipcode {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 15px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.i-am-a-text {
		padding-bottom: 0.65rem;
		float: none;
		text-align: center;
	}

	.go-button {
		width: 36px;
		height: 35px;
	}

	.zip-text {
		width: 150px;
		height: 34px;
	}

	.zipcode-form-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.donate-buttons-section {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.donate-button-container {
		width: 90%;
	}

	.donate-text {
		margin-right: 5px;
		font-size: 0.75rem;
	}

	.content-box-section {
		top: 150px;
		margin-top: 0rem;
		background-image: -webkit-linear-gradient(270deg, transparent 10%, hsla(180, 2%, 61%, 0.26) 37%, hsla(180, 2%, 63%, 0.27) 38%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent 10%, hsla(180, 2%, 61%, 0.26) 37%, hsla(180, 2%, 63%, 0.27) 38%, #9ea2a2);
	}

	.cb-wrapper {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-bottom: 1rem;
	}

	.cb-content {
		padding-left: 1.5rem;
	}

	.cb-content._2 {
		padding-left: 1.5rem;
	}

	.cb-image {
		width: 50%;
	}

	.cb-text-heading {
		width: 50%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 0rem;
		font-size: 1.55rem;
		line-height: 1.8rem;
	}

	.column-wrapper {
		width: 100%;
	}

	.social-media-section {
		padding-top: 0rem;
	}

	.social-media-container {
		width: 93%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.social-image {
		padding: 0px;
	}

	.social-image.twitter {
		margin-top: 0px;
		padding: 0px;
	}

	.social-image.facebook {
		padding: 0px;
	}

	.social-image.linkedin {
		padding: 1px 0px 0px 1px;
	}

	.social-image-link {
		width: 35px;
		height: 35px;
		padding: 3px 6px 6px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-color: transparent;
		border-radius: 20px;
	}

	.donate-button {
		width: 25px;
		height: 25px;
	}

	.footer-container {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.donate-link {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.link-block.new {
		left: 40%;
	}

	.select-cadet-dropdown {
		width: 150px;
	}

	.connect-with-us {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: auto;
		margin-right: auto;
		margin-bottom: 14px;
		margin-left: auto;
		padding-top: 6px;
		padding-bottom: 6px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-image: -webkit-linear-gradient(270deg, #001489, #001489);
		background-image: linear-gradient(180deg, #001489, #001489);
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		text-align: center;
	}

	.connect-with-us.mobile-portrait {
		display: none;
	}

	.inside-slider {
		height: 200px;
	}

	.landing-page-title-wrapper {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.h1-heading {
		font-size: 1.8rem;
		line-height: 1.175em;
	}

	.h2 {
		font-size: 1.55rem;
		line-height: 1.3em;
	}

	.h3 {
		font-size: 1.3rem;
		line-height: 1.3em;
	}

	.h4 {
		font-size: 1.1rem;
	}

	.blockquote {
		font-size: 1.025em;
		line-height: 1.4em;
	}

	.paragraph-text {
		font-size: 0.9rem;
		line-height: 1.4em;
	}

	.list-text {
		font-size: 0.9rem;
		line-height: 1.4em;
	}

	.news-section {
		padding-bottom: 0px;
	}

	.cap-wrapper {
		width: auto;
		padding-top: 0rem;
	}

	.content-box-section-new {
		background-image: -webkit-linear-gradient(270deg, transparent, #a5a4a4 25%, #a5a5a5 54%, #a4a5a5 65%, #9ea2a2), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, transparent, #a5a4a4 25%, #a5a5a5 54%, #a4a5a5 65%, #9ea2a2), url('/20170713122722/local/public/shared/assets/images/websites/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, contain, auto;
		background-repeat: repeat, no-repeat, repeat;
	}

	.learn-more-heading {
		margin-bottom: 0em;
		padding-top: 3.3rem;
		padding-bottom: 3.3rem;
		font-size: 3rem;
	}
}

@media (max-width: 353px) {
	li.footer-signoff-list-item:first-of-type {
		margin-bottom: 1rem;
	}
}

/* Set root font size */
html {
	font-size: 1rem;
}

/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

/* Superscript */
sup {
	font-size: xx-small;
}

/* Skip to text link */
.skip-to-content {
	color: #000;
	background: #fff;
}

/* Clear input field styling */
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* --- Style Nav Slider Dots --- */
/* .w-num .w-round or omit for square*/
.w-slider-dot.w-active {
	background-color: #ba0c2f !important;
	position: relative;
}

/* Set divider style */
hr {
	color: #ab1b20;
	border-color: #ab1b20;
	border: 0px;
	border-top: 1px solid;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
}

/* Footer phone number auto-styling on mobile devices */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: inherit;
}

/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
}

xh1 > a:hover {
	color: inherit;
}

/* Text selection style for Mozilla based browsers */
::-moz-selection {
	background: #001489;
	color: #fff;
}

/* Text selection style for Safari browser */
::selection {
	background: #001489;
	color: #fff;
}

/* Change Color of Form Placeholder Text */
input.input-box,
textarea {
	background: white;
	color: black;
}

.w-input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #777;
	opacity: 1;
}

.w-input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #777;
	opacity: 1;
}

.w-input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #777;
	opacity: 1;
}

.w-input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #777;
	opacity: 1;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

/*mmenu*/
.mobile-navigation-menu {
	display: none !important;
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-opened {
		display: block !important;
		width: 80% !important;
		margin: 0;
		max-width: none;
		min-width: 0;
	}
}

html.mm-opening .mm-slideout {
	transform: translate(80%, 0px);
	z-index: 0;
}

/*remove default link styles*/
.mm-listview > li > a,
.mm-listview > li > span {
	margin-bottom: 1px;
	padding: 1em 1rem;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.375rem;
	text-align: left;
	text-transform: uppercase;
}

.mm-listview > li > a.color-red {
	background-color: #ba0c2f;
}

.mm-menu .mm-listview > li > a:not(.mm-next) > br,
.mm-menu .mm-listview > li > span > br {
	display: none;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #ba0c2f;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listview > li > a:not(.mm-next):hover,
.mm-menu .mm-listview > li > span:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

/*remove default borders*/
.mm-listview > li:not(.mm-divider)::after {
	border-bottom-width: 0;
}

/*next links*/
.mm-listview > li .mm-next::before {
	border-left-color: transparent;
	border-left: 1px solid hsla(0, 0%, 100%, 0.25) !important;
}

.mm-listview .mm-next {
	width: 25%;
	box-shadow: none;
	margin-bottom: -1px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
	background-color: rgba(0, 0, 0, 0.15);
}

.mm-listview .mm-next:hover {
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listview .mm-next + .nav-link {
	width: 75%;
	margin-right: 25%;
}

/*next link arrow*/
.mm-next::after,
.mm-arrow::after {
	right: 45%;
	height: 9px;
	width: 9px;
}

.mm-menu .mm-listview > li .mm-next::after,
.mm-menu .mm-listview > li .mm-arrow::after {
	border-color: hsla(0, 0%, 100%, 0.5);
}

/*second level and previous links*/
.mm-prev::before {
	left: 17px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -2px;
}

.mm-panels .mm-panel:first-of-type .mm-listview {
	margin-top: -60px;
}

.mm-navbar {
	background-color: rgba(0, 0, 0, 0.15);
	height: 58px;
	box-shadow: none;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
	margin-left: 0;
	margin-right: 0;
	color: #fff;
	text-align: left;
	padding: 0 30px;
}

.mm-navbar .mm-btn {
	height: 58px;
}

.mm-menu .mm-navbar .mm-btn::before,
.mm-menu .mm-navbar .mm-btn::after {
	border-color: hsla(0, 0%, 100%, 0.5);
}

.mm-navbar .mm-title {
	color: #fff !important;
	padding: 1em 0;
	font-size: 1.125rem;
	line-height: 1.375rem;
	text-align: left;
	font-weight: 400;
	text-transform: uppercase;
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 992px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 768px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}
/*inside pages*/
/*messages*/
.success {
	display: block;
	background-color: #167c28;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #ffffff;
}

.error {
	display: block;
	background-color: #ba0c2f;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #ffffff;
}

.success p,
.success ul li,
.error p,
.error ul li {
	margin-bottom: 0;
	margin-top: 0;
	color: #ffffff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(255, 255, 255, 0.75);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0.04em;
	color: white;
	background-color: #001489;
	border-bottom: 2px solid white;
	padding: 0.625em 0.625rem;
	text-align: left;
}

table.styled thead tr th h1,
table.styled thead tr th h2,
table.styled thead tr th h3,
table.styled thead tr th h4,
table.styled thead tr th h5,
table.styled thead tr th h5 {
	color: #fff;
}

table.styled th {
	font-weight: normal;
	letter-spacing: 0.02em;
	padding: 0.5em 0.625rem;
	background-color: #afb2b2;
	border-bottom: 2px solid white;
	color: black;
	text-align: left;
}

table.styled thead th a {
	color: #fff;
	border-bottom-color: rgba(255, 255, 255, 0.3);
}

table.styled thead th a:hover {
	color: #fff;
	border-bottom-color: rgba(255, 255, 255, 1);
}

table.styled tbody th a {
	color: #000;
	border-bottom-color: rgba(0, 0, 0, 0.3);
}

table.styled tbody th a:hover {
	color: #000;
	border-bottom-color: #000;
}

table.styled td {
	padding: 0.375em 0.625rem;
}

table.styled.striped tr:nth-child(even) {
	background: #e5e5e5;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/*tablesaw*/
@media (max-width: 39.9375em) {
	table.tablesaw.styled {
		border: 1px solid rgba(0, 0, 0, 0.15);
	}

	table.tablesaw.styled tbody {
		border-top: 1px solid rgba(0, 0, 0, 0.15);
	}

	table.tablesaw.styled th,
	table.tablesaw.styled td {
		border-right: 0 none;
	}
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {

	.presentation-table td {
		display: inline-block !important;
		border-right: none !important;
	}

	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="search"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875em;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #cccccc;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
	color: #000;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*links without border*/
a.no_border,
a.no-border {
	border: 0 none;
}

/*related documents*/
.box.documents .content > table {
	margin-bottom: 10px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
}

/* ************************************ feature pages ************************************ */
/* ****************** accounts ****************** */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 360px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 7px;
	color: #001489;
	font-size: 1.875rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
	font-family: Ubuntu, Helvetica, sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/* ****************** blog reader (RSS) ****************** */
#feeds_module.cms_list .cms_title h3 {
	margin-top: 0;
	margin-bottom: 5px;
}

#feeds_module.cms_list .cms_date h4 {
	color: #444;
	font-size: 1rem;
	line-height: 1.5em;
	margin-top: 0;
	margin-bottom: 5px;
}

#feeds_module.cms_list .abstract {
	padding: 0;
}

/* ****************** events ****************** */
/*list & calendar views*/
#events_module.cms_list .event-current-day {
	padding-top: 1.5em;
}

#events_module.cms_list .cms_title h3 {
	margin-top: 0;
}

#events_module .event-category-label {
	font-size: 0.875rem;
	display: inline-block;
	margin-right: 7px;
	margin-bottom: 0;
}

#events_module.cms_list .event-current-day #event-calendar-link {
	margin-top: 6px;
}

#events_module #fullcalendar-controls #agenda {
	margin-top: 6px;
}

.full_calendar_view .events_module_calendar .fc-toolbar .fc-center h2 {
	line-height: 1.4375em;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-size: 1rem;
	margin-top: 10px;
	margin-bottom: 0;
	clear: both;
}

#events_module.cms_list .cms_list_item .cms_date + .location-event-list-view.list-view-bottom-padding {
	padding-top: 10px;
}

#events_module.cms_list .back-link {
	display: none;
}

/*details view*/
#events_module.cms_entity .cms_content > .cms_divider:first-of-type {
	display: none;
}

#events_module.cms_entity .cms_content .events-detail-date {
	margin-top: 0;
	line-height: 1.5em;
}

/*form view*/
#events_module.cms_form .cms_label {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-color: transparent;
	background-position: center 10px;
}

#events_module.cms_form .cms_non_required {
	background: transparent;
}

#events_module.cms_form .cms_field {
	background: transparent;
}

@media (max-width: 991px) {
	/*form view*/
	#events_module.cms_form .cms_label {
		width: auto;
		display: inline;
		text-align: left;
	}

	#events_module.cms_form .cms_field {
		width: 100%;
		display: block;
	}

	#events_module.cms_form .cms_required {
		width: 15px;
		display: inline-block;
		background-position: center 5px;
	}

	#events_module.cms_form .cms_non_required {
		display: inline-block;
	}

	#events_module.cms_form .cms_field input[type="text"] {
		width: 100% !important;
		max-width: 300px !important;
	}

	#events_module.cms_form form > table:last-of-type td {
		display: none;
	}

	#events_module.cms_form form > table:last-of-type td:last-of-type {
		display: block;
	}
}

@media (max-width: 767px) {
	/*list & calendar views*/
	#events_module.cms_list .event-current-day #event-calendar-link {
		float: none;
		display: block;
	}

	#events_module.cms_list .event-current-day .cms_title {
		display: block;
		float: none;
	}
	/*form view*/
	#events_module.cms_form .cms_info_req {
		float: none;
		text-align: right;
	}
}

/* ****************** faqs ****************** */
/*list view*/
#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	color: #444;
	list-style-type: disc;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	list-style-type: none;
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item:first-of-type {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h3,
#faqs_module.cms_list .cms_list_subitem .cms_title h3 {
	margin-top: 0;
}

/*right sidebar*/
.box.tools .content .contentbox_item form input[type="text"] {
	width: 100%;
}

.box.tools .content .contentbox_item form > p {
	margin-bottom: 0;
}

/* ****************** jobs ****************** */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	color: #444;
	font-family: Ubuntu, Helvetica, sans-serif;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(0, 0, 0, 0.15);
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: sub;
}

#jobs_module.cms_form .cms_field input[type="text"] {
	width: 275px !important;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
	margin: 0 !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.375rem 1rem;
}

/* sidebar job search filtering */
.right-sidebar-column .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-sidebar-column .box #frmDepartments #Department {
	width: calc(100% - 45px);
}

.right-sidebar-column .box #frmDepartments button[type="submit"].primary {
	padding: 9px 10px !important;
	display: inline;
	float: right;
}

.right-sidebar-column .box table.contentbox_item {
	margin-bottom: 0.75em;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
		padding-left: 0;
	}

	#jobs_module.cms_form .cms_field input[type="text"] {
		max-width: 275px;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}

	#jobs_module.cms_form form > p {
		text-align: left;
	}
}

/* ****************** news ****************** */
/*main list view*/
#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_categories_section .cms_category_item h3 {
	margin-top: 0;
}

/*category list view*/
.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

/*details view*/
#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

/*right sidebar*/
.box.archive {
	overflow: hidden;
}

@media (max-width: 991px) {
	/*details view*/
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}
}

@media (max-width: 767px) {
	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/* ****************** newsletters ****************** */
/*list view*/
#newsletters_module.list .title h3 {
	margin-top: 0;
}

/*form view*/
#newsletters_module.form .required_message {
	margin-bottom: 20px;
}

#newsletters_module.form .form_container td {
	padding-bottom: 15px;
}

@media (max-width: 767px) {
	#newsletters_module.form .form_item > td {
		display: block;
		width: 100%;
		padding-bottom: 10px;
	}

	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		text-align: left;
	}

	#newsletters_module.form .form_item.required .label label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 5px;
	}

	#newsletters_module.form .form_item.required .field {
		padding-left: 0;
		background: none;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}
}

/* ****************** partners ****************** */
/*list view*/
#partners_module td.image img {
	width: 150px;
	height: auto;
}

#partners_module h3 {
	margin-top: 0;
}

/*right sidebar*/
.box.partner-document .contentbox_item_image {
	width: 20px;
}

.box.partner-document .contentbox_item {
	margin-bottom: 15px;
}

@media (max-width: 991px) {
	#partners_module.cms_list td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list td.image {
		padding-bottom: 10px;
	}
}

/* ****************** photo albums ****************** */
/*list view*/
#photoalbums_module.cms_list .cms_list_item .cms_image {
	padding-right: 1.5em;
}

#photoalbums_module.cms_list .cms_list_item .cms_image img {
	width: calc(160px - 1.5em);
	height: auto;
}

#photoalbums_module.cms_entity .cms_footer {
	margin-bottom: 20px;
}

/*details view*/
#photoalbums_module .cms_footer {
	margin-top: 20px;
}

@media (max-width: 991px) {
	/*list view*/
	#photoalbums_module td {
		display: block;
		width: 100% !important;
	}

	#photoalbums_module td .cms_metadata1.cms_title {
		margin-top: 5px;
	}
}

/* ****************** posts ****************** */
/*list views*/
.collection.posts-collection > .node.posts-node {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 1.5em 0 0 0;
	padding: 1.5em 0 0 0;
}

.collection.posts-collection > .node.posts-node:first-of-type {
	border-top: 0 none;
	margin: 0;
	padding: 0;
}

.posts-node .posts-field h2 {
	margin-top: 0;
}

/*details view*/
.posts-node .posts-comments {
	margin-top: 1.5em;
}

.posts-node .post-comments-title {
	background: #001489;
}

.posts-node .post-comment {
	padding: 1em 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.posts-node .post-comment-author {
	font-size: 0.75rem;
}

/*right sidebar*/
.posts-blocks.posts-blocks-searches .posts-blocks-search-form input.posts-blocks-search-field[type="text"] {
	width: calc(100% - 48px);
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 9px 12px 6px !important;
}

.posts-blocks-searches {
	padding-top: 0.75em;
}

.posts-blocks-rss-feeds {
	padding-top: 1.5em;
}

.posts-blocks-subscribes {
	border-top: 0 none;
	padding-top: 0;
}

.right-sidebar-column .posts-blocks-rss-feed-link,
.posts-blocks-subscribe a {
	border: 0 none;
}

.posts-blocks-authors .posts-blocks-author,
.posts-blocks-authors .posts-blocks-posts,
.posts-blocks-categories .posts-blocks-category {
	margin-bottom: 0.75em;
}

/* ****************** resources ****************** */
#moduleDocuments_module .folder .icon {
	margin-bottom: 6px;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

#moduleDocuments_module .cms_items .cms_description,
#moduleDocuments_module.cms_list .cms_uncategorized_module_documents .cms_description {
	background-position: left 6px;
}

#moduleDocuments_module .folder h3 {
	margin-top: 0;
}

#moduleDocuments_module .cms_items .cms_description h4 {
	margin-top: 0;
}

.moduleDocuments-rss-feed-link {
	border-bottom: 0 none !important;
}

/*************** Staff Directory ***************/
/***********************************************/
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}


/* ****************** submission forms ****************** */
/*form views*/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .formSection,
.module-forms .formSection {
	margin: 1.5em 0;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*multi forms*/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

/*review submission view*/
.module-forms.confirmation .step-title {
	color: #001489;
	font-size: 1.5rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
	margin-top: 0.5em;
}

@media (max-width: 991px) {
	/*review submission view*/
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	/*form views*/
	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after {
		content: "*";
		color: #ff0000;
		padding-left: 5px;
	}

	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #ff0000;
		padding-left: 5px;
	}
}

/* ****************** testimonials ****************** */
/*list view*/
#testimonials_module.cms_list .cms_list_item .testimonial-link {
	border-bottom: 0 none;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-abstract {
	margin-bottom: 0;
}

/* ****************** videos OK ****************** */


/* ****************** NEW LANDING PAGE LAYOUT ****************** */
.header-menu-wrap.secondary-nav-wrap {
	height: auto;
	border-top: 1px solid #000;
	background-color: #879bc3;
}

.header-menu-inside-wrap.secondary-menu-inside-wrap {
	display: block;
	height: 40px;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.nav-container.secondary-nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-image: none;
	position: relative;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.nav-menu-wrap.secondary-menu-wrap {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	float: left;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-left-style: none;
	background-color: transparent;
	background-image: none;
}

.seccondary-nav-link.secondary-nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-width: 17.75%;
	height: 40px;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right-style: none;
	color: #000;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	text-decoration: none;
}

.seccondary-nav-link.secondary-nav-link:hover {
	color: #001489;
	text-decoration: none;
}

.inside-landing-page .slider-image {
	background-position: 0px 0px, 0px 0px, 100% 0px;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-color: #000;
}

.inside-landing-page .slider-caption-title {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	text-align: left;
	margin-bottom: 1.25rem;
	color: #fff;
	font-size: 2.3rem;
	line-height: 3rem;
}

.inside-landing-page .slide-caption-para {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	font-weight: 600;
	text-align: left;
	text-transform: none;
	margin-bottom: 1.25rem;
	color: #ccc;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #ccc;
}

.inside-landing-page .slide-caption-para p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.inside-landing-page .slide-caption-para td,
.inside-landing-page .slide-caption-para li,
.inside-landing-page .slide-caption-para span,
.inside-landing-page .slide-caption-para a,
.inside-landing-page .slide-caption-para a:hover {
	color: #ccc;
}

.inside-landing-page .main-section {
	background: #9ea2a2;
}

.inside-landing-page .event-overview-section {
	background: #d7e1f5;
}

.inside-landing-page .container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.inside-landing-page .event-overview-heading {
	padding: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.75rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

.inside-landing-page .event-overview-heading p {
	margin: 0;
}

.inside-landing-page .four-sections-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.75rem;
	padding-left: 1.75rem;
}

.inside-landing-page .event-colomn {
	width: 100%;
	padding: 1rem;
}

.inside-landing-page .event-overview-subheading {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.inside-landing-page .event-paragraph {
	padding-bottom: 1rem;
}

.inside-landing-page .featured-keynotes-section {
	background: #f8f8f8;
}

.inside-landing-page .keynote-heading {
	padding: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.75rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

.inside-landing-page .keynote-heading p {
	margin: 0;
}


.inside-landing-page .four-sections-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.75rem;
	padding-left: 1.75rem;
}

.inside-landing-page .keynote-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	justify-content: center;
}

.inside-landing-page .keynote-column {
	width: 50%;
	padding: 1rem;
}

.inside-landing-page .keynote-image {
	width: 100%;
	border-radius: 100%;
}

.inside-landing-page .event-overview-subheading {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.inside-landing-page .keynote-paragraph {
	padding-bottom: 1rem;
}

.inside-landing-page .session-schedule-section {
	background: #879bc3;
}

.inside-landing-page .container.sessions-events {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 2.75rem;
	justify-content: flex-start;
	align-items: center;
}

.inside-landing-page .sessions-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	padding-bottom: 1rem;
	padding-left: 2.75rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.inside-landing-page .sessions-heading {
	padding-top: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.inside-landing-page .sessions-paragraph {
	width: 100%;
	padding-bottom: 1rem;
	color: #000;
}

.inside-landing-page .events-button {
	padding: 0.5rem 1rem;
	background-color: #fff;
	color: #222527;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
}

.inside-landing-page .events-button:hover {
	background: #ccc;
	color: #000;
}

.inside-landing-page .sessions-image-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 25%;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.inside-landing-page .sessions-image {
	width: 100%;
	height: 100%;
}

.inside-landing-page .agenda-section {
	background: #aebcd9;
}

.inside-landing-page .agenda-heading-wrapper {
	padding-right: 2.75rem;
	padding-left: 2.75rem;
}

.inside-landing-page .agenda-heading {
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .day-agenda-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 1rem;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .tabbed-agenda-wrapper {
	width: 100%;
	padding-left: 0.5rem;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed {
	color: #fff;
	border-left: 2px solid rgba(0, 0, 0, 0.2);
	background-color: #6f7777;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed.current {
	background: #001489;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .tab-heading-text {
	color: #fff;
	font-family: 'Open Sans', sans-serif;
}

.inside-landing-page #events_module.homepage_contentbox.conference-tabs .individual-event-time-wrapper {
	border-bottom: 1px solid hsla(180, 2%, 63%, 0.25);
}

.inside-landing-page .event-title {
	color: #0e3c9c;
	font-weight: 700;
}

.inside-landing-page .event-title a {
	color: inherit;
	text-decoration: none;
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
}

.inside-landing-page .event-title a:hover {
	opacity: 0.8;
}

.inside-landing-page .venue-info-section {
	background-color: #d7e1f5;
	display: block;
}

.inside-landing-page .container.venue {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 2.75rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-landing-page .venue-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-bottom: 1rem;
	padding-left: 2.75rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.inside-landing-page .venue-info-heading {
	padding-top: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.75rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.inside-landing-page .venue-info-subheading,
.inside-landing-page .venue-info-wrapper h2,
.inside-landing-page .venue-info-wrapper h3,
.inside-landing-page .venue-info-wrapper h4 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #3f3f3f;
	font-size: 1.25rem;
	text-transform: none;
	margin: 0;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0;
}

.inside-landing-page .venue-paragraph,
.inside-landing-page .venue-info-wrapper p {
	padding-bottom: 1rem;
	color: #222527;
	margin: 0;
}

.inside-landing-page .venue-info-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.inside-landing-page .transportation-link,
.inside-landing-page .venue-info-wrapper a,
.inside-landing-page .google-maps-wrapper a {
	margin-right: 1rem;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	color: #000;
	font-weight: 600;
	text-decoration: none;
}

.inside-landing-page .transportation-link:hover,
.inside-landing-page .venue-info-wrapper a:hover {
	border-bottom-color: #fff;
	color: #454a4a;
}

.inside-landing-page .google-maps-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 1rem;
	padding-right: 0rem;
	padding-bottom: 1rem;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.inside-landing-page .html-embed,
.inside-landing-page .google-maps-wrapper p {
	width: 100%;
	height: 300px;
	margin: 0;
}

@media (max-width: 991px) {
	.inside-landing-page .slider-image {
		background-position: 0px 0px, 0px 0px, 0px 0px, 100% 0px;
		background-size: auto, auto, auto, cover;
		background-repeat: repeat, repeat, repeat, no-repeat;
	}

	.inside-landing-page .slider-caption-title {
		width: 100%;
		margin-bottom: 0.75rem;
		font-size: 2.05rem;
		line-height: 2.7rem;
	}

	.inside-landing-page .slide-caption-para {
		width: 100%;
		margin-bottom: 0.75rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.inside-landing-page .container {
		max-width: 100%;
	}

	.inside-landing-page .four-sections-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-landing-page .event-colomn {
		width: 50%;
	}

	.inside-landing-page .four-sections-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-landing-page .keynote-wrapper {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-landing-page .keynote-column._1 {
		width: 30%;
	}

	.inside-landing-page .keynote-column._2 {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.inside-landing-page .container.sessions-events {
		padding-right: 2.75rem;
	}

	.inside-landing-page .sessions-image-wrapper {
		padding-right: 0rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.inside-landing-page .sessions-image {
		width: 100%;
		height: 100%;
	}

	.inside-landing-page .container.venue {
		padding-right: 2.75rem;
	}

	.inside-landing-page .google-maps-wrapper {
		padding-right: 0rem;
	}
}

@media (max-width: 767px) {
	.inside-landing-page .slider-image {
		background-position: 0px 0px, 0px 0px, 0px 0px, 100% 0px;
		background-repeat: repeat, repeat, repeat, no-repeat;
	}

	.inside-landing-page .slider-caption-title {
		margin-bottom: 0.25rem;
		font-size: 1.85rem;
		line-height: 2.15rem;
	}

	.inside-landing-page .slide-caption-para {
		margin-bottom: 0.35rem;
		font-size: 0.95rem;
		line-height: 1.4rem;
	}

	.inside-landing-page .main-section {
		box-shadow: 0 0 6px 1px #9ea2a2;
	}

	.inside-landing-page .keynote-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-landing-page .keynote-column {
		width: 100%;
	}

	.inside-landing-page .keynote-column._1 {
		display: block;
		width: 35%;
		min-height: 200px;
		min-width: 200px;
		margin-right: auto;
		margin-left: auto;
	}

	.inside-landing-page .keynote-column._2 {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}

	.inside-landing-page .container.sessions-events {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.inside-landing-page .sessions-text-wrapper {
		width: 65%;
		padding-right: 1rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inside-landing-page .sessions-image-wrapper {
		width: 35%;
		min-height: 200px;
		min-width: 200px;
	}

	.inside-landing-page .sessions-image {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.inside-landing-page .day-agenda-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-landing-page .tabbed-agenda-wrapper {
		padding-left: 0rem;
	}

	.inside-landing-page #events_module.homepage_contentbox.conference-tabs .tab-heading-text {
		font-size: 0.9rem;
	}

	.inside-landing-page .container.venue {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-landing-page .venue-info-wrapper {
		width: 100%;
		padding-right: 1.75rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inside-landing-page .transportation-link,
	.inside-landing-page .venue-info-wrapper a {
		font-size: 0.9rem;
	}

	.inside-landing-page .google-maps-wrapper {
		width: 100%;
		padding-right: 0rem;
		padding-left: 2.75rem;
	}

	.inside-landing-page #events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed {
		border-style: solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.2);
	}
}

@media (max-width: 479px) {
	.inside-landing-page .slider-image {
		background-position: 0px 0px, 100% 0px;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.inside-landing-page .slider-caption-title {
		margin-bottom: 0.35rem;
		font-size: 1.445rem;
		line-height: 1.65rem;
		color: #001489;
	}

	.inside-landing-page .slide-caption-para {
		font-size: 0.85rem;
		color: #151618;
	}

	.inside-landing-page .slide-caption-para br {
		display: none;
	}

	.inside-landing-page .container {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.inside-landing-page .event-overview-heading {
		font-size: 1.35rem;
	}

	.inside-landing-page .four-sections-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-landing-page .event-colomn {
		width: 100%;
		padding: 0rem;
	}

	.inside-landing-page .keynote-heading {
		font-size: 1.35rem;
	}

	.inside-landing-page .four-sections-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-landing-page .keynote-column._1 {
		width: 75%;
	}

	.inside-landing-page .keynote-column._2 {
		padding: 0rem;
	}

	.inside-landing-page .container.sessions-events {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-landing-page .sessions-text-wrapper {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inside-landing-page .sessions-heading {
		font-size: 1.35rem;
	}

	.inside-landing-page .sessions-image-wrapper {
		width: 100%;
		height: auto;
		min-height: auto;
		min-width: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.inside-landing-page .sessions-image {
		width: 100px;
	}

	.inside-landing-page .agenda-heading-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-landing-page .agenda-heading {
		font-size: 1.35rem;
	}

	.inside-landing-page .tabbed-agenda-wrapper {
		padding-left: 0rem;
	}

	.inside-landing-page .container.venue {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-landing-page .venue-info-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-landing-page .venue-info-heading {
		font-size: 1.35rem;
	}

	.inside-landing-page .venue-info-subheading,
	.inside-landing-page .venue-info-wrapper h2,
	.inside-landing-page .venue-info-wrapper h3,
	.inside-landing-page .venue-info-wrapper h4 {
		font-size: 1rem;
	}

	.inside-landing-page .google-maps-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-landing-page .html-embed,
	.inside-landing-page .google-maps-wrapper p {
		height: 200px;
	}
}


/* gdpr */
#cookie-consent-form-container form .consent-header {
	background: #f2f3f5;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	display: block;
}

#cookie-consent-container .consent-disclaimer a {
	color: #fff;
}

#cookie-consent-form a {
	color: #001489;
}


/* slideshow */
.slider.homepage-slideshow {
	display: block;
	width: 100%;
	height: auto;
}

.main-slidesshow {
	height: 27vw;
	background-color: #f1f1f1;
}

.main-slidesshow .w-slide {
	height: auto;
}

.main-slidesshow ~ .slider-indicator .w-slider-dot {
	background-color: #ccc;
}

.main-slidesshow ~ .slider-indicator .w-slider-dot.w-active {
	background-color: #ba0c2f !important;
}

.main-slidesshow ~ .slider-arrow {
	left: auto;
	top: auto;
	right: 1rem;
	bottom: 1.5rem;
	width: 3rem;
	height: 3rem;
	background-color: #ba0c2f;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	border-radius: 0px;
}

.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
	margin-right: 3.5rem;
}

.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
	display: block;
	margin-right: auto;
	margin-left: auto;
	font-size: 1.5rem;
}

.main-slidesshow ~ .slider-arrow .w-icon-slider-left:before {
	content: "\e601";
}

.main-slidesshow ~ .slider-arrow .w-icon-slider-right:before {
	content: "\e600";
}

.main-slidesshow ~ .slider-indicator {
	padding-top: 0.75rem;
	font-size: 0.75rem;
	top: auto;
}

.main-slidesshow .slide-image {
	display: block;
	width: 70%;
	height: 27vw;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.main-slidesshow .slide-image.no-caption {
	width: 100%;
}

.main-slidesshow .slide-caption-wrapper {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem 2% 4.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: rgba(241, 241, 241, 0.8);
}

.main-slidesshow .slide-caption-wrapper p,
.main-slidesshow .slide-caption-wrapper li,
.main-slidesshow .slide-caption-wrapper span {
	letter-spacing: 0.02em;
	color: #151618;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-align: left;
	display: inline-block;
	width: 100%;
	text-transform: none;
}

.main-slidesshow .slide-caption-header {
	width: 100%;
	margin-top: 0rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2.1rem;
	font-weight: 600;
	margin-bottom: 2px;
}

.main-slidesshow .slide .more-link {
	display: inline-block;
	background-color: #ba0c2f;
	box-shadow: none;
	color: #fff;
	margin-top: 1em;
	margin-bottom: 0.25em;
	font-size: 0.85rem;
	text-align: center;
	transition: all 300ms ease;
	padding: 0.5em 1em;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.main-slidesshow .slide .more-link:hover {
	background-color: #001489;
	color: #fff;
}

@media (max-width: 991px) {
	.main-slidesshow {
		height: 48vw;
	}

	.main-slidesshow ~ .slider-arrow {
		right: 1rem;
		bottom: 1rem;
		width: 2.25rem;
		height: 2.25rem;
	}

	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		margin-right: 3rem;
	}

	.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
		display: block;
		margin-right: auto;
		margin-left: auto;
		color: #fff;
	}

	.main-slidesshow .slide {
		background-color: #f1f1f1;
	}

	.main-slidesshow .slide-image {
		width: 100%;
		height: 48vw;
	}

	.main-slidesshow .slide-caption-wrapper {
		display: block;
		width: 35%;
		padding: 1rem 1.1rem 3.5rem 1.3rem;
		background-color: rgba(241, 241, 241, 0.8);
	}

	.main-slidesshow .slide-caption-wrapper p,
	.main-slidesshow .slide-caption-wrapper li,
	.main-slidesshow .slide-caption-wrapper span {
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.main-slidesshow .slide-caption-header {
		width: auto;
		max-width: 310px;
		min-width: 232px;
		font-size: 1.4em;
		line-height: 1.8rem;
	}

	.main-slidesshow .slide-caption-paragraph,
	.main-slidesshow .slide-caption-wrapper p {
		overflow: hidden;
		width: 100%;
		max-height: 170px;
		font-size: 0.9rem;
		line-height: 1.5em;
	}

	.main-slidesshow .slide .more-link {
		margin-top: 0rem;
	}
}

@media (max-width: 767px) {
	.main-slidesshow {
		height: auto;
	}

	.main-slidesshow ~ .slider-arrow {
		width: 2rem;
		height: 2rem;
	}

	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		width: 2rem;
		height: 2rem;
		margin-right: 2.5rem;
	}

	.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
		font-size: 1rem;
	}

	.main-slidesshow .slide {
		position: relative;
		background-color: transparent;
		background-image: none;
	}

	.main-slidesshow .slide-image {
		height: 40vw;
	}

	.main-slidesshow .slide-caption-wrapper {
		position: static;
		display: block;
		width: 100%;
		padding-bottom: 2.5rem;
		background-image: none;
	}

	.main-slidesshow .slide-caption-wrapper p,
	.main-slidesshow .slide-caption-wrapper li,
	.main-slidesshow .slide-caption-wrapper span {
		font-size: 0.83rem;
		line-height: 1.25rem;
	}

	.main-slidesshow .slide-caption-header {
		font-size: 1.2em;
		line-height: 1.1em;
	}
}

@media (max-width: 479px) {
	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		margin-right: 2.5rem;
	}

	.main-slidesshow .slide-image {
		height: 43vw;
	}

	.main-slidesshow .slide-caption-header {
		line-height: 1.3em;
	}

	.main-slidesshow .slide-caption-header {
		font-size: 1.3em;
		line-height: 1.55rem;
	}
}


/* Alert Box */
.special-notice-alert-box {
	position: relative;
	display: none;
	margin-right: auto;
	margin-left: auto;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: none;
	overflow: hidden;
}

.special-notice-alert-box .special-notice-container-2 {
	position: relative;
	display: flex;
	height: auto;
	min-height: 52px;
	border-bottom: 3px solid #dbc368;
	background-color: #001489;
	color: #151618;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-transform: none;
	justify-content: space-between;
}

.special-notice-alert-box .special-notice-title-holder-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 150px;
	min-height: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ba0c2f;
}

.special-notice-alert-box .special-notice-title-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}

.special-notice-alert-box .div-block-11 {
	position: relative;
	z-index: 1;
	width: 80px;
	height: 40px;
	border: 1.5px solid #fff;
	background-color: #001489;
	align-self: center;
}

.special-notice-alert-box .special-notice-close-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	padding: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1.5px solid #fff;
	background-color: transparent;
	cursor: pointer;
	margin-right: 20px;
	margin-left: 11px;
	align-self: center;
}

.special-notice-alert-box .special-notice-text-holder-2 {
	padding-left: 20px;
	width: calc(100% - 300px);
	min-height: 37px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 20px;
}

.special-notice-alert-box .slider-4 {
	height: auto;
	background-color: transparent;
	text-align: left;
	display: flex;
	align-items: center;
	min-height: auto;
	justify-content: center;
	width: 100%;
	overflow: hidden;
}

.special-notice-alert-box .left-arrow-3 {
	left: auto;
	right: 37px;
	display: block;
	width: 40px;
}

.special-notice-alert-box .icon-6 {
	font-size: 1.5rem;
}

.special-notice-alert-box .right-arrow-3 {
	display: block;
	width: 40px;
}

.special-notice-alert-box .icon-7 {
	font-size: 1.5rem;
}

.special-notice-alert-box .slider-alert {
	margin-left: 0px;
	padding-right: 0px;
	display: none;
	height: auto;
}

.special-notice-alert-box .slider-alert *,
.special-notice-alert-box .slider-alert p,
.special-notice-alert-box .slider-alert p * {
	color: #fff !important;
	background-color: transparent;
}

.special-notice-alert-box .slider-alert p,
.special-notice-alert-box .slider-alert p * {
	line-height: 1.4rem;
}

.special-notice-alert-box .slider-alert p a {
	color: #549fc4 !important;
	text-decoration: underline;
	transition: none;
}

.special-notice-alert-box .slider-alert p a:hover {
	color: #fff !important;
	text-decoration: underline;
}

.special-notice-alert-box .slider-alert p:last-child {
	margin-bottom: 0px;
}

.special-notice-alert-box .div-block-10 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.special-notice-alert-box .div-block-8 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.special-notice-alert-box .text-block-9 {
	color: #fff;
	font-size: 1rem;
	text-align: center;
}

.special-notice-alert-box .bold-text-2 {
	font-size: 1rem;
	line-height: 1.1rem;
	font-weight: bold;
	text-align: center;
}

@media screen and (max-width: 991px) {
	.special-notice-alert-box .special-notice-title-holder-2 {
		width: 100px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		line-height: 1.2rem;
	}

	.special-notice-alert-box .div-block-11 {
		width: 63px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		width: 32px;
		margin-right: 10px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		width: calc(100% - 225px);
		padding-left: 35px;
		padding-right: 7px;
	}

	.special-notice-alert-box .slider-4 {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
	}

	.special-notice-alert-box .left-arrow-3 {
		width: 30px;
		right: 30px;
	}

	.special-notice-alert-box .icon-6 {
		margin-right: 0px;
		margin-left: 6px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .right-arrow-3 {
		width: 30px;
	}

	.special-notice-alert-box .icon-7 {
		margin-right: 0px;
		margin-left: 4px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .slider-alert {
		padding-right: 0;
	}

	.special-notice-alert-box .div-block-10 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.special-notice-alert-box .div-block-8 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.special-notice-alert-box .text-block-9 {
		font-size: 0.7rem;
		line-height: 1.2rem;
	}

	.special-notice-alert-box .bold-text-2 {
		color: #fff;
		font-size: 0.8rem;
		line-height: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.special-notice-alert-box .special-notice-container-2 {
		min-height: 150px;
		height: auto;
		margin-right: auto;
		flex-wrap: wrap;
		padding: 0px 0px 10px 0px;
	}

	.special-notice-alert-box .special-notice-title-holder-2 {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		height: 10%;
		min-height: 30px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		font-size: 0.9rem;
		line-height: 1rem;
		text-align: right;
	}

	.special-notice-alert-box .div-block-11 {
		position: relative;
		left: auto;
		right: 0px;
		width: 63px;
		height: 30px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		width: 32px;
		height: 30px;
		margin-right: 10px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		flex-grow: 1;
		margin-top: 0px;
		margin-left: 10px;
		padding-right: 0px;
		padding-left: 0px;
		min-height: 70px;
	}

	.special-notice-alert-box .slider-4 {
		margin-left: 0px;
		padding-top: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
	}

	.special-notice-alert-box .left-arrow-3 {
		width: 30px;
		right: 30px;
	}

	.special-notice-alert-box .icon-6 {
		margin-left: 5px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .right-arrow-3 {
		width: 30px;
	}

	.special-notice-alert-box .icon-7 {
		margin-left: 5px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .slider-alert {
		overflow: hidden;
		padding-right: 5px;
	}

	.special-notice-alert-box .div-block-10 {
		margin-right: auto;
	}

	.special-notice-alert-box .div-block-8 {
		overflow: visible;
		width: 100%;
		margin-left: 0px;
		padding: 0px;
		font-size: 0.8rem;
	}

	.special-notice-alert-box .text-block-9 {
		font-size: 0.7rem;
		line-height: 1.2rem;
	}

	.special-notice-alert-box .bold-text-2 {
		margin-left: 0px;
	}

	.special-notice-alert-box .slider-alert *,
	.special-notice-alert-box .slider-alert p,
	.special-notice-alert-box .slider-alert p * {
		line-height: 1.2rem;
	}
}

@media screen and (max-width: 479px) {
	.special-notice-alert-box .special-notice-container-2 {
		min-height: 175px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.special-notice-alert-box .special-notice-title-holder-2 {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		min-height: 10%;
		height: auto;
		margin-bottom: 0px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		padding-right: 2px;
		padding-bottom: 0px;
		padding-left: 2px;
		font-size: 0.8rem;
		line-height: 0.9rem;
		text-align: center;
		letter-spacing: -0.9px;
	}

	.special-notice-alert-box .div-block-11 {
		position: relative;
		left: auto;
		right: 0px;
		width: 63px;
		height: 30px;
		margin-right: 15px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30px;
		margin-right: 12px;
		margin-left: 0px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		margin-top: 5px;
		margin-right: 10px;
		margin-left: 10px;
		padding-left: 0px;
	}

	.special-notice-alert-box .slider-alert {
		overflow: hidden;
		padding-right: 0px;
	}

	.special-notice-alert-box .div-block-10 {
		margin-right: 0px;
		margin-left: 0px;
	}

	.special-notice-alert-box .div-block-8 {
		width: 100%;
	}

	.special-notice-alert-box .text-block-9 {
		color: #fff;
		text-align: left;
	}

	.special-notice-alert-box .bold-text-2 {
		color: #fff;
		font-size: 0.7rem;
		text-align: left;
	}
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}

.module-search input[type="search"] {
	padding: 12px 10px;
}


/************** Translate ********************/
/*************************************************/

.translation-desktop-wrapper {
	position: relative;
}

.translation-desktop-wrapper .translation-container {
	transform: scale(0, 0);
	opacity: 0;
	position: absolute;
	background-color: #d3d3d3;
	padding: 0.5rem;
	z-index: 999;
	left: 14px;
}

.translation-desktop-wrapper:hover .translation-container {
	animation: fadeTranslateIn 0.2s linear forwards;
}

@keyframes fadeTranslateIn {
	0% {
		opacity: 0;
		transform: scale(1, 1);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

.translation-desktop-wrapper .secondary-header-item-cta-link.translate_button {
	cursor: pointer;
	display: block;
	max-width: 140px;
	background-image: url("/20170713122722/local/public/shared/assets/images/websites/keyboard-down-arrow-button.svg");
}

.translation-desktop-wrapper .secondary-header-item-cta-link.translate_button:hover {
	background-color: #ba0c2f !important;
}

@media (max-width: 991px) {

	.header-contents-wrap {
		transition: all 0.3s ease;
	}

	.header-contents-wrap.with-translation {
		margin-bottom: 70px;
	}

	.translation-desktop-wrapper {
		display: none;
	}

	.translate-container-mobile .google-translation-container-mobile {
		position: absolute;
		top: 58px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		align-content: space-around;
		flex-direction: row-reverse;
		right: 0rem;
		background-color: #d3d3d3;
		height: 75px;
		overflow: hidden;
		transition: all 0.3s ease;
	}

	.translate-container-mobile .google-translation-container-mobile.hide {
		height: 0;
	}

	.translate-container-mobile {
		display: block;
	}

	.translate-container-mobile > a {
		margin: 15px 0px 0px 0px;
		display: block;
		padding: 15px 15px;
		cursor: pointer;
		background-image: url('/20170713122722/local/public/shared/assets/images/websites/translate-icon-white.svg');
		background-repeat: no-repeat;
		margin-right: 5rem;
	}

	#google_translate_element_mobile {
		margin: 0.5rem 0;
	}
}

@media (min-width: 992px) {
	.translate-container-mobile {
		display: none;
	}
}
